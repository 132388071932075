exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-admissions-js": () => import("./../../../src/pages/admissions.js" /* webpackChunkName: "component---src-pages-admissions-js" */),
  "component---src-pages-alumni-js": () => import("./../../../src/pages/alumni.js" /* webpackChunkName: "component---src-pages-alumni-js" */),
  "component---src-pages-bulletin-board-js": () => import("./../../../src/pages/bulletin-board.js" /* webpackChunkName: "component---src-pages-bulletin-board-js" */),
  "component---src-pages-campus-js": () => import("./../../../src/pages/campus.js" /* webpackChunkName: "component---src-pages-campus-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-landing-acupuncture-js": () => import("./../../../src/pages/landing/acupuncture.js" /* webpackChunkName: "component---src-pages-landing-acupuncture-js" */),
  "component---src-pages-our-faculty-js": () => import("./../../../src/pages/our-faculty.js" /* webpackChunkName: "component---src-pages-our-faculty-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-programs-certificate-programs-acupuncture-and-moxibustion-js": () => import("./../../../src/pages/programs/certificate-programs/acupuncture-and-moxibustion.js" /* webpackChunkName: "component---src-pages-programs-certificate-programs-acupuncture-and-moxibustion-js" */),
  "component---src-pages-programs-certificate-programs-asian-bodywork-therapy-js": () => import("./../../../src/pages/programs/certificate-programs/asian-bodywork-therapy.js" /* webpackChunkName: "component---src-pages-programs-certificate-programs-asian-bodywork-therapy-js" */),
  "component---src-pages-programs-certificate-programs-tcm-preparatory-certificate-js": () => import("./../../../src/pages/programs/certificate-programs/tcm-preparatory-certificate.js" /* webpackChunkName: "component---src-pages-programs-certificate-programs-tcm-preparatory-certificate-js" */),
  "component---src-pages-programs-diploma-programs-acupuncture-and-moxibustion-js": () => import("./../../../src/pages/programs/diploma-programs/acupuncture-and-moxibustion.js" /* webpackChunkName: "component---src-pages-programs-diploma-programs-acupuncture-and-moxibustion-js" */),
  "component---src-pages-programs-diploma-programs-advanced-tcmp-js": () => import("./../../../src/pages/programs/diploma-programs/advanced-TCMP.js" /* webpackChunkName: "component---src-pages-programs-diploma-programs-advanced-tcmp-js" */),
  "component---src-pages-programs-diploma-programs-herbology-js": () => import("./../../../src/pages/programs/diploma-programs/herbology.js" /* webpackChunkName: "component---src-pages-programs-diploma-programs-herbology-js" */),
  "component---src-pages-programs-diploma-programs-tcmp-js": () => import("./../../../src/pages/programs/diploma-programs/TCMP.js" /* webpackChunkName: "component---src-pages-programs-diploma-programs-tcmp-js" */),
  "component---src-pages-programs-index-js": () => import("./../../../src/pages/programs/index.js" /* webpackChunkName: "component---src-pages-programs-index-js" */),
  "component---src-pages-teaching-clinic-js": () => import("./../../../src/pages/teaching-clinic.js" /* webpackChunkName: "component---src-pages-teaching-clinic-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-career-post-js": () => import("./../../../src/templates/career-post.js" /* webpackChunkName: "component---src-templates-career-post-js" */),
  "component---src-templates-event-post-js": () => import("./../../../src/templates/event-post.js" /* webpackChunkName: "component---src-templates-event-post-js" */)
}

